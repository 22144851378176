import React from "react";
import {graphql} from "gatsby";
import { Container, Col, Row } from 'react-grid-system';

import SEO from "../components/Seo";
import Layout from "../layouts";
import {ContentCard, MediaCard, ProductCard, ProfileCard} from "../components/Card";
import Section from "../components/Section";
import FeaturedList from "../components/FeaturedList";
import Hero from "../components/Hero";
import ContentTabs from '../components/ContentTabs';
import Accordion from '../components/Accordion';
import MediaText from '../components/MediaText';
import Typeform from "../components/Typeform";
import MarketoForm from "../components/MarketoForm";
import Video from "../components/Video";
import InfoPane from "../components/InfoPane";
import Quote from "../components/Quote";
import Announcement from "../components/Announcement";

import {contentCard, mediaCard, productCard, profileCard, startups} from '../../data/data';

const Demo = (props) => {
  const data = props.data;
  const tabs = props.data.contentfulFaqPage;
  const video = props.data.contentfulVideo;
  const infoPane = props.data.contentfulInfoPane;
  const cards = props.data.contentfulMediaGridSection;
  const typeform = props.data.contentfulTypeform;
  const featureList = props.data.contentfulFeatureList;
  const quote = props.data.contentfulBlockquote;
  const marketoForm = props.data.contentfulMarketoForm;
  const announcement = props.data.contentfulCountdown;

  console.log('Hello from fix no 2');

  const sectionStyle = {
    marginTop: '20px',
    marginBottom: '0',
    textTransform: 'uppercase',
    letterSpacing: '1px'
  };

  // Text alignment for MediaGrid
  const textAlignment = cards.sectionTitleAlignment || 'left';

  return (
    <Layout>
      <SEO title="Demo" keywords={[`application`, `react`]} />

      <Container>
        <h2 style={sectionStyle}>- Hero -</h2>
      </Container>

      <Section fullBleed={true}>
        <Col>
          <Hero {...data.contentfulHero} />
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Announcement -</h2>
      </Container>

      <Section fullBleed={true}>
        <Col>
          <Announcement {...announcement}/>
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Info Pane -</h2>
      </Container>

      <Section fullBleed={true}>
        <Col>
          <InfoPane key={infoPane.id} items={infoPane.items}/>
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Full Image Cards -</h2>
      </Container>

      <Container>
        <h2 style={sectionStyle}>- Content Cards -</h2>
      </Container>

      <Section>
        <Col md={4}>
          <ContentCard {...contentCard} />
        </Col>
        <Col md={4}>
          <ContentCard {...contentCard} />
        </Col>
        <Col md={4}>
          <ContentCard {...contentCard} />
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Media Cards -</h2>
      </Container>

      <Section>
        <Col md={4}>
          <MediaCard {...mediaCard} />
        </Col>
        <Col md={4}>
          <MediaCard {...mediaCard} />
        </Col>
        <Col md={4}>
          <MediaCard {...mediaCard} />
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Product Cards -</h2>
      </Container>

      <Section>
        <Col md={4}>
          <ProductCard {...productCard} />
        </Col>
        <Col md={4}>
          <ProductCard {...productCard} />
        </Col>
        <Col md={4}>
          <ProductCard {...productCard} />
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Profile Cards -</h2>
      </Container>

      <Section>
        <Col xs={12} sm={6} md={4}>
          <ProfileCard {...profileCard} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <ProfileCard {...profileCard} />
        </Col>
        <Col xs={12} sm={6} md={4}>
          <ProfileCard {...profileCard} />
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Media Grid Section with Centered Title -</h2>
      </Container>

      <Section key={cards.id} fullBleed={cards.fullBleed}>
        <Col>
          {cards.sectionTitle && (
            <h2 style={{textAlign: textAlignment}}>{cards.sectionTitle}</h2>
          )}
          {cards.sectionSubtitle && (
            <div style={{textAlign: textAlignment, marginBottom: "48px"}} dangerouslySetInnerHTML={{
              __html: cards.sectionSubtitle.childMarkdownRemark.html
            }}/>
          )}
          <Row>
            {cards.components && cards.components.map(component => {
              let sectionContent;

              if (component.__typename === 'ContentfulSponsor') {
                const MediaCard = require("../components/Card").MediaCard;
                sectionContent = <MediaCard {...component} link={`sponsors/${component.slug}`}/>;
              }

              if (component.__typename === 'ContentfulCard') {
                let Card = ContentCard;

                if (typeof window !== 'undefined' && typeof component.cardType !== 'undefined') {
                  Card = require("../components/Card")[`${component.cardType}Card`];
                }

                sectionContent = <Card {...component}/>;
              }

              if (component.__typename === 'ContentfulSpeaker') {
                const ProfileCard = require("../components/Card").ProfileCard;
                const slugify = require('slugify');
                const name = slugify(`${component.firstName} ${component.lastName}`, {lower: true});
                const slug = slugify(`${name} ${component.contentful_id}`, {lower: true, remove: /[^0-9A-Za-z\s-]/g});

                sectionContent = <ProfileCard {...component}
                  name={`${component.firstName} ${component.lastName}`}
                  image={component.imageSrc}
                  link={`/agenda/#/speakers/${slug}`}
                />;
              }

              if (component.__typename === 'ContentfulPerson') {
                const ProfileCard = require("../components/Card").ProfileCard;
                const slugify = require('slugify');
                const name = slugify(`${component.firstName} ${component.lastName}`, {lower: true});
                const slug = `${slugify(component.personRole, {lower: true})}/${name}`;

                sectionContent = <ProfileCard {...component}
                  name={`${component.firstName} ${component.lastName}`}
                  image={component.image}
                  showLinkedIn={true}
                  link={slug}
                />;
              }

              if (component.__typename === 'ContentfulTextBlock') {
                sectionContent = (
                  <div dangerouslySetInnerHTML={{
                    __html: component.content.childMarkdownRemark.html
                  }}/>
                )
              }

              return (
                <Col xs={12} sm={6} md={12 / cards.itemsNumber} key={component.id}>
                  {sectionContent}
                </Col>
            )})}

            {cards.images && cards.images.map(image => (
              <Col md={12 / cards.itemsNumber} sm={6} xs={6} key={image.id}>
                <img src={image.file.url} alt={image.title} style={{margin: 'auto', display: 'block'}}/>
              </Col>
            ))}
          </Row>
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Quote -</h2>
      </Container>

      <Section fullBleed={true}>
        <Col>
          <Quote {...quote}/>
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Media Text -</h2>
      </Container>

      <Section>
        <MediaText {...startups.section1} />
      </Section>

      <Section>
        <MediaText {...startups.section1} orientation="right"/>
      </Section>

      <Section>
        <MediaText {...startups.section1} orientation="down" content={{childMarkdownRemark: {html: "All speaking proposals must be submitted through the form linked below. Proposals submitted directly to the Content Team will not be considered."}}}/>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Featured List -</h2>
      </Container>

      <Section>
        <Col xs={12}>
          <FeaturedList {...featureList}/>
        </Col>
        <Col xs={12}>
          <FeaturedList {...featureList} hasIcon={false} />
        </Col>
      </Section>

      <Container>
        <h2 style={sectionStyle}>- Tabs with Accordions -</h2>
      </Container>

      <Section>
        <Col xs={12}>
        {tabs.contentTabs && (
          <ContentTabs activeTab={{id: tabs.contentTabs[0].title}}>
            {tabs.contentTabs.map(tab => (
              <ContentTabs.Tab key={tab.id} id={tab.title} title={tab.title}>
                {tab.content && tab.content[0].items ?
                  <Accordion>
                    {tab.content[0].items.map(item => (
                      <Accordion.Item key={item.id} title={item.title}>
                        <div dangerouslySetInnerHTML={{__html: item.content[0].content.childMarkdownRemark.html}}></div>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                  :
                  null
                }
              </ContentTabs.Tab>
            ))}
          </ContentTabs>
        )}
      </Col>
    </Section>

    <Container>
      <h2 style={sectionStyle}>- Embedded Video -</h2>
    </Container>

    <Section>
      <Col>
        <Video videoSrc={video.videoUrl} ratio={video.aspectRatio} title={video.title}/>
      </Col>
    </Section>

    <Container>
      <h2 style={sectionStyle}>- Marketo Forms -</h2>
    </Container>

    <Section>
      <Col xs={12} md={6} lg={4} push={{ md: 3, lg: 4 }}>
        <MarketoForm {...marketoForm}/>
      </Col>
    </Section>

      <Container>
        <h2 style={sectionStyle}>- Typeform -</h2>
      </Container>

      <Section>
        <Col xs={12}>
          <Typeform {...typeform}/>
        </Col>
      </Section>
    </Layout>
  )
};

export default Demo;

export const query = graphql`
   query DemoQuery {
     contentfulHero(id: {eq: "d3f10fa2-7a36-5d9d-b2d1-bc9a4b16de7e"}) {
      id,
        image {
          file {
            url
          }
        },
        videoUrl,
        title,
        subtitle,
        cta {
          label,
          url,
          gtmEventName,
          gtmEventType
      }
    }
    contentfulVideo {
      id,
      videoUrl,
      aspectRatio
    }
    contentfulMediaGridSection(id: {eq: "14c94453-131f-5a6e-bae5-6fd1e8951d9b"}) {
      id
      sectionTitleAlignment
      sectionTitle
      fullBleed
      itemsNumber
      components {
        ... on Node {
          ... on ContentfulCard {
            id
            cardType
            title
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
    contentfulInfoPane {
      id,
      items {
        id,
        title,
        subtitle,
        link
      }
    }
    contentfulFaqPage(title: {eq: "FAQ"}) {
        contentTabs {
            id,
            title,
            content {
                id
                items {
                    id
                    title,
                    content {
                        ... on Node {
                            __typename
                            ... on ContentfulTextBlock {
                                id,
                                content {
                                    childMarkdownRemark {
                                        html
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
      },
       contentfulTypeform {
        formIdentifier,
        height
       },
       contentfulFeatureList {
         id,
         title,
         hasIcon,
           featureListItems {
           id,
           content
         }
       }
       contentfulBlockquote {
         id,
         text,
         author,
         company,
         position
       }
       contentfulCountdown {
         id,
         title,
         dateAndTime
       }
       contentfulMarketoForm {
         formId,
         title,
         gtmEventName
       }
    }
`;
