import React from 'react';
import PropTypes from 'prop-types';

import {trackEvent} from "../../utils";

import './marketoForm.scss';

class MarketoForm extends React.Component {
  constructor(props) {
    super(props);

    this.marketoConfig = {
      podId: "//app-lon07.marketo.com",
      munchkinId: "652-GAM-809"
    };

    this.formLoadHandler = this.formLoadHandler.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.resetStyles = this.resetStyles.bind(this);
  }

  componentDidMount() {
    let script = document.createElement('script');
    script.onload = this.handleForm;
    script.src = "//app-lon07.marketo.com/js/forms2/js/forms2.min.js";
    document.body.appendChild(script);
  }

  resetStyles(form) {
    const formEl = form.getFormElem()[0];

    const stylesBase = document.getElementById("mktoForms2BaseStyle");
    const stylesTheme = document.getElementById("mktoForms2ThemeStyle");

    stylesBase.disabled = true;
    stylesTheme.disabled = true;

    const inlineMarketoStyle = stylesTheme.nextSibling;
    if (inlineMarketoStyle && inlineMarketoStyle.textContent && inlineMarketoStyle.textContent.indexOf(`mktoForm_${this.props.formId}`) >= 0) {
      inlineMarketoStyle.disabled = true;
    }

    const inFormStyles = formEl.getElementsByTagName("style");
    if (inFormStyles.length) {
      for (let i = 0; i < inFormStyles.length; i++) {
        inFormStyles[i].disabled = true;
      }
    }

    let styledEls = Array.from(formEl.querySelectorAll("[style]")).concat(formEl);
    styledEls.forEach(el => el.removeAttribute("style"));
  }

  formLoadHandler(form) {
    //Add an onSuccess handler
    form.onSuccess(function() {
      const formEl = document.getElementById(`mktoForm_${form.getId()}`);
      const eventName = formEl.dataset.title || '';
      trackEvent("Form Submission", eventName);
      return true;
    });
  }

  handleForm() {
    // eslint-disable-next-line no-undef
    MktoForms2.loadForm(
      this.marketoConfig.podId,
      this.marketoConfig.munchkinId,
      this.props.formId,
      this.formLoadHandler
    );
    // eslint-disable-next-line no-undef
    MktoForms2.whenRendered(form => setTimeout(() => this.resetStyles(form), 0));
  }

  render() {
    return (
      <React.Fragment>
        <form id={`mktoForm_${this.props.formId}`} className="gs-marketo-form" data-title={this.props.gtmEventName}/>
      </React.Fragment>
    )
  }
}

MarketoForm.defaultProps = {
  formId: ''
};

MarketoForm.propTypes = {
  formId: PropTypes.string.isRequired,
  gtmEventName: PropTypes.string
};

export default MarketoForm;