import React from 'react';
import PropTypes from 'prop-types';
import styles from './infoPane.module.scss';
import {Link} from "gatsby";

function InfoPane(props) {
  return (
    <div className={styles.pane}>
      <div className={styles.content}>
        {props.items.map(item => {
          let Tag = item.link ? Link : 'div';

          return (
            <Tag key={item.id} className={styles.item} to={item.link}>
              <p className={styles.title}>{item.title}</p>
              <p className={styles.text}>{item.subtitle}</p>
            </Tag>)
        })}
      </div>
    </div>
  );
}

InfoPane.propTypes = {
  items: PropTypes.array
};

export default InfoPane;
