import React from 'react';
import PropTypes from 'prop-types';

import styles from './typeform.module.scss';

class Typeform extends React.Component {
  componentDidMount() {
    let js,q,d=document, gi=d.getElementById, ce=d.createElement, gt=d.getElementsByTagName, id="typef_orm", b="https://embed.typeform.com/";
    if(!gi.call(d,id)) {
      js=ce.call(d,"script"); js.id=id; js.src=b+"embed.js"; q=gt.call(d,"script")[0]; q.parentNode.insertBefore(js,q)
    }
  }

  render() {
    const height = !!this.props.height ? this.props.height : '500px';

    return (
      <React.Fragment>
        <div className={`${styles.wrapper} typeform-widget`}
          data-url={`https://m2020.typeform.com/to/${this.props.formIdentifier}`}
          style={{height: height}}/>
        <div className={styles.powered}> powered by
          <a href="https://admin.typeform.com/signup?utm_campaign=UEkQqt&utm_source=typeform.com-12413856-Pro&utm_medium=typeform&utm_content=typeform-embedded-poweredbytypeform&utm_term=EN"
             className={styles.link}
             target="_blank"
             rel="noopener noreferrer">Typeform
          </a>
        </div>
      </React.Fragment>
    )
  }
};

Typeform.defaultProps = {
  formIdentifier: 'UEkQqt',
  height: '500px'
};

Typeform.propTypes = {
  formIdentifier: PropTypes.string.isRequired,
  height: PropTypes.string
};

export default Typeform;