import React from 'react';
import PropTypes from 'prop-types';
import Button from '../Button';

import styles from './hero.module.scss';

function Hero(props) {
  return (
     <div className={styles.hero}>
       {props.image && (
         <div className={styles.image} style={{backgroundImage: `url('${props.image.file.url}')`}}></div>
       )}

      {props.videoUrl && (
        <div className={styles.videoContainer}>
          <video
            className={styles.video}
            poster={props.image.file.url}
            loop={true}
            autoPlay
            muted
          >

            <source
              src={props.videoUrl}
              type={"video/mp4"} />
            Your browser doesn't support HTML5 video tag.
          </video>
        </div>
      )}

       <div className={styles.content}>
         <h1 className={styles.title}>{props.title}</h1>
         {props.subtitle && (
           <h2 className={styles.subtitle}>{props.subtitle}</h2>
         )}

         {props.cta && (
          <Button
            link={props.cta.url}
            variant="primary"
            isMobileFull
            istargetblank={props.cta.istargetblank}
            gtmEventName={props.cta.gtmEventName}
            gtmEventType={props.cta.gtmEventType}
          >
            {props.cta.label}
          </Button>
         )}
       </div>
     </div>
  );
}

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  videoUrl: PropTypes.string
};

Hero.defaultProps = {
  title: 'Title',
  subtitle: null
};

export default Hero;
