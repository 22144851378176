import React from 'react';
import PropTypes from 'prop-types';
import Section from "../Section";
import { Col } from 'react-grid-system';

import styles from './quote.module.scss';

function Quote(props) {
  return (
    <div className={styles.quote}>
      <Section size="small" isCentered>
        <Col md={9}>
          <div className={styles.content}>{props.text}</div>
          {props.author && (
            <div className={styles.text}>{props.author}</div>
          )}
          {props.position && (
            <div className={styles.text}>{props.position}</div>
          )}
          {props.company && (
            <div className={styles.text}>{props.company}</div>
          )}
        </Col>
      </Section>
    </div>
  );
};

Quote.propTypes = {
  author: PropTypes.string,
  company: PropTypes.string,
  position: PropTypes.string,
  text: PropTypes.string
};

export default Quote;
