import React  from "react";
import PropTypes from 'prop-types';

import Tab from "./tab";

import styles from './contentTabs.module.scss';
import classNames from "classnames/bind";

let cx = classNames.bind(styles);

class ContentTabs extends React.Component {
  static Tab = Tab;

  constructor(props) {
    super(props);

    this.tabsElements = {};

    this.state = {
      tabs: [],
      prevActiveTab: {},
      activeTab: this.props.activeTab
    };

    this.addTab = this.addTab.bind(this);
    this.onClick = this.onClick.bind(this);
    this.removeTab = this.removeTab.bind(this);
  }

  addTab(newTab) {
    const isNewTabFound = this.state.tabs.find((tab) => tab.id === newTab.id);

    if (isNewTabFound) {
      return;
    }

    this.setState(prevState => ({
      tabs: prevState.tabs.concat(newTab)
    }));
  };

  removeTab(tabId) {
    this.setState(prevState => ({
      tabs: prevState.tabs.filter(tab => tab.id !== tabId)
    }));
  };

  onClick(tab) {
    return () => (
      this.setState(prevState => ({
        prevActiveTab: prevState.activeTab,
        activeTab: tab
      }))
    )
  };

  render() {
    return (
      <div className={styles.tabs}>
        <div className={styles.container}>
          <ul className={styles.list}>
            {this.state.tabs.map((tab, index) => {
              const tabClassName = cx({
                listItem: true,
                listItemActive: this.state.activeTab.id === tab.id
              });


              return (
                <li
                  className={tabClassName}
                  key={index}
                  onClick={this.onClick(tab)}
                  id={tab.id}
                  ref={tabElement => this.tabsElements[tab.id] = tabElement}
                >
                  {tab.title}
                </li>
              )
            })}
          </ul>
        </div>

        {React.Children.map(this.props.children, child => {
          if (!child) return;

          return (
            React.cloneElement(child, {
              activeTab: this.state.activeTab,
              addTab: this.addTab
            }))
          }
        )}
      </div>
    );
  }
}

ContentTabs.propTypes = {
  activeTab: PropTypes.object.isRequired
};

ContentTabs.defaultProps = {
  activeTab: {id: ''},
};

export default ContentTabs;

