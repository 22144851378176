import React from 'react';
import PropTypes from 'prop-types';
import Countdown from 'react-countdown-now';
import moment from 'moment';
import 'moment/locale/en-gb'

import styles from './timer.module.scss';

function Timer(props) {
  moment.locale();
  const date = moment(props.dateAndTime).format('LLLL');
  
  // Renderer callback with condition
  const renderer = (props) => {
    if (props.completed) {
      // Render a completed state
      return <span className={styles.endMessage}>Oops! Time is up!</span>;
    }
    // Render a countdown
    return (
      <div className={styles.timer}>
        <ul className={styles.list}>
          <li className={styles.item}>
            <div className={styles.value}>{props.formatted.days}</div>
            <div className={styles.info}>days</div>
          </li>
          <li className={styles.item}>
            <div className={styles.value}>{props.formatted.hours}</div>
            <div className={styles.info}>hours</div>
          </li>
          <li className={styles.item}>
            <div className={styles.value}>{props.formatted.minutes}</div>
            <div className={styles.info}>minutes</div>
          </li>
          <li className={styles.item}>
            <div className={styles.value}>{props.formatted.seconds}</div>
            <div className={styles.info}>seconds</div>
          </li>
        </ul>
      </div>
    );
  };

  return (
      <Countdown
        date={date}
        renderer={renderer}
        zeroPad={2}
      />
  );
}

export default Timer;

Timer.propTypes = {
  title: PropTypes.string,
  dateAndTime: PropTypes.string.isRequired
};
