import React from 'react';
import PropTypes from 'prop-types';
import Section from "../Section";
import { Col } from 'react-grid-system';
import Timer from '../Timer';

import styles from './announcement.module.scss';

function Announcement(props) {
  return (
    <div className={styles.announcement}>
      <Section size="small">
        <Col>
          <div className={styles.wrapper}>
            <div className={styles.title}>{props.title}</div>
            <Timer {...props} />
          </div>

        </Col>
      </Section>
    </div>
  );
}

Announcement.propTypes = {
  title: PropTypes.string,
  dateAndTime: PropTypes.string
};

export default Announcement;
