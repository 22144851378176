import React from 'react';
import PropTypes from 'prop-types';
import styles from './featuredList.module.scss';

function FeaturedList(props) {
  return (
    <div className={styles.featuredList}>
      <h2 className={styles.title}>{props.title}</h2>
      <ul className={styles.list}>
        {props.featureListItems.map(item => (
          <li className={styles.listItem} key={item.id}>
            {props.hasIcon && (
              <i className={`${styles.icon} fas fa-check`}></i>
            )}
            <div dangerouslySetInnerHTML={{ __html: item.content}} className={styles.content}></div>
          </li>
        ))}
      </ul>
    </div>
  );
};

FeaturedList.propTypes = {
  content: PropTypes.string,
  hasIcon: PropTypes.bool,
  items: PropTypes.array,
  title: PropTypes.string
};

FeaturedList.defaultProps = {
  hasIcon: true,
};

export default FeaturedList;
