import React from "react";
import PropTypes from 'prop-types';

class Tab extends React.Component {
  componentDidMount() {
    this.props.addTab({
      id: this.props.id,
      title: this.props.title
    });
  }

  render() {
    return this.props.activeTab.id === this.props.id && this.props.children;
  }
}

Tab.propTypes = {
  activeTab: PropTypes.object.isRequired,
  addTab: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

Tab.defaultProps = {
  activeTab: {id: ''},
  addTab: () => {},
  id: '',
  title: ''
};

export default Tab;
